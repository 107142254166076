import React from 'react'
import AnimLogo from '../../../../images/seventa-logo-events.svg'

export const seventaLogoEvents = () => {
    return (
        <div>
            <AnimLogo />
        </div>
    )
}

export default seventaLogoEvents